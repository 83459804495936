<template>
  <div class="index">
    <div class="title-serch">
      <!--      <span style="white-space: nowrap;margin-left: 10px;color:#1890ff">-->
      <!--        团队1111-->
      <!--        <a-icon type="swap"/>-->
      <!--      </span>-->
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="搜索"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/roomMessageBatchSend/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="add" />
          </div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <div>
      </div>

      <div class="item" v-if="messageList.length > 0">
        <div class="item-body">
          <div>
            <div @click.stop="detailItem(item)" v-for="(item,index) in messageList" :key="index" class="body-item">
              <div style="    width: 69%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;">
                <span>{{ item.batchTitle }}</span>
                <i style="    font-style: normal;
    background: #1890ff;
    color: #fff;
    font-size: 12px;
    padding: 1px;
    border-radius: 4px;">{{ item.sendStatus != 0 ? '已发送' : '待发送'}}</i>
              </div>
              <div>
                共 {{item.sendEmployeeTotal}} 名员工需发送，<span style="color: orange;">{{item.notSendTotal}} </span>名员工未发送
              </div>
              <div style="    max-width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;">
                {{item.createdAt}} 由 {{item.creatorName}} 创建
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty
          v-else
          class="custom-image"
          :image="require('../../assets/images/search-no-data.svg')"
          description="暂无数据"
      />
    </div>
  </div>
</template>

<script>
import {roomMessageBatchSend} from "../../api/roomMessageBatchSend";
export default {
  components:{
  },
  data() {
    return {
      // 文字
      serchValue: '',
      message: '',
      // tbas切换
      tabsChose: 0,
      // 分组名称
      groupName: '',
      // 数据
      messageList: []
    }
  },
  watch: {},
  created() {
    this.codeData()
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    routerPush(e) {
      this.$router.push(e)
    },
    // 搜索
    onSearch(){
      this.codeData(this.serchValue)
    },
    clearSerch(){
      this.serchValue = ''
      this.codeData()
    },
    //清除
    clear(){
      this.groupName = ''
      this.codeData()
    },
    // 获取数据
    codeData(batchTitle){
      roomMessageBatchSend({batchTitle}).then(res=>{
        this.messageList = res.data.list
      })
      // channelCodeIndex({
      //   groupId: this.groupName.groupId ? this.groupName.groupId : 0,
      //   name
      // }).then(res=>{
      //   this.messageList = res.data
      // })
    },

    // 编辑
    editCode(item){
      this.$router.push({
        path:"/channelCode/create",
        query:{
          id: item.channelCodeId
        }
      })
    },
    // 詳情
    detailItem(i){
      this.$router.push({
        path: '/roomMessageBatchSend/details',
        query: {
          id: JSON.stringify(i.id)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}
/deep/.van-tabs__line{
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/.van-empty__image img{
  object-fit: contain;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }
  .main-agency {
    //width: 280px;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 2;
    width: 150px;
    /* height: 127px; */
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .title-serch{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .bets{
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 86px;
    /* background: red; */
    height: 25px !important;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    span{
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
      display: block;
    }
  }
  .bet{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    background: #f4f5f7;
    min-height: 94%;
    //padding-top: 5px;
    margin-top: -15px;

    img {
      width: 22px;
      height: 22px;
    }
    .item{
      padding: 12px 16px;
      width: 100%;
      background: #f4f5f7;
      .title{
        width: 100%;
        font-size: 12px;
        div:nth-child(1){
          color: #444;
          font-size: 12px;
        }
        img{
          width: 14px;
          height: 14px;
        }
      }
      .item-body{
        .body-item{
          background: #ffffff;
          padding: 18px 15px;
          margin-top: 10px;
          border-radius: 12px;
          font-size: 12px;
          color: #585757;
          position: relative;
          div{
            margin-bottom: 5px;
          }
          div:nth-child(1){
            color: #444;
            margin-bottom: 10px;
            span{
              color: #000;
              border-radius: 5px;
              font-size: 16px;
              margin-right: 9px;
            }
          }
          div:last-child{
            margin-top: 10px;
            color: #939393;
          }
          .img{
            position: absolute;
            right: 14px;
            width: 90px;
            height: 90px;
            top: 47%;
            margin: 0 !important;
            padding: 0 !important;
            transform: translateY(-50%);
            img:nth-child(1){
              width: 100%;
              height: 100%;
            }
            img:nth-child(2){
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          .operation{
            margin-bottom: -9px;
            margin-top: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>
