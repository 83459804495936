<template>
  <div class="work-contact-send" style="padding: 10px;background: #F2F4F8;    height: 100%;">
    <div class="block">
      <van-cell required size="large" title="向我的客户群群发消息"/>
      <div class="content">
        <div class="tips">
          <p>客户群群发给每个客户每个月仅可收到4条客户群群发消息</p>
          <p>若管理员在后台创建了客户群群发也会占用群发机会</p>
          <p>个人群发每天可给客户发送一条群发消息</p>
        </div>
        <van-cell-group link inset style="margin: 0;" :border="false">
          <van-field
              v-model="name"
              label="群发名称"
              required
              placeholder="请输入群发名称"
              input-align="right"
          />
        </van-cell-group>
        <van-cell @click="userGet" required is-link title="选择群主">
          {{userName || '请选择'}}
          <selectEmployee v-show="false" @change="getCount" ref="groups"/>
        </van-cell>
      </div>
    </div>

    <div class="block">
      <div class="content">
        <div class="form">
          <div class="input">
            <div class="input-block-box">
              <textarea
                  class="textarea"
                  contenteditable="true"
                  v-model="msgText"
              />
              <div class="msg-list" v-if="msgList.length > 0">
                <div class="item" v-for="(v,i) in msgList">
                  <a-icon type="minus-circle" @click="msgList.splice(i,1)"/>
                  <span>【{{ map[v.msgType] }}】</span>
                  ：
                  <span v-if="v.msgType === 'image'"><img :src="v.fullPath"></span>
                  <span v-else>{{ v.title }}</span>
                </div>
              </div>
              <div class="add" @click="$refs.annexPopup.show()">
                <a style="color: #000">
                  <img src="../../assets/add-file.svg" style="margin-right: 5px;width: 25px;" alt="">
                  添加附件
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button style="margin-right: 16px;" @click="$router.push('/')">取消</a-button>
      <a-button type="primary" @click="addClick">创建群发</a-button>
    </div>

    <annexPopup ref="annexPopup" @change="e => msgList.push(e)"/>
  </div>
</template>

<script>
import selectEmployee from "../../components/select/employee/select";
import annexPopup from "../../components/annex/popup";
import {add} from "../../api/roomMessageBatchSend";

export default {
  data() {
    return {
      name: '',
      msgList: [],
      msgText: '',
      map: {
        image: '图片',
        link: '链接',
        miniprogram: '小程序'
      },
      userName: ''
    }
  },
  methods: {
    addClick() {
      const employeeIds = this.$refs.groups.get().map(v => {
        return v.id
      });

      if (!this.name) {
        this.$message.error('群发名称未填写');

        return false;
      }

      if (!employeeIds.length) {
        this.$message.error('群主未选择');

        return false;
      }

      if (!this.msgText && this.msgList.length === 0) {
        this.$message.error('群发内容未填写');

        return false;
      }

      let contentParams;
      if (this.msgText.length !== 0) {
        contentParams = JSON.stringify([
          {
            msgType: 'text',
            content: this.msgText
          },
          ...this.msgList
        ])
      } else {
        contentParams = JSON.stringify([
          ...this.msgList
        ])
      }
      contentParams = JSON.parse(contentParams)
      contentParams.forEach(item=>{
        if(item.appid){
          item.path = item.pic.path
        }
      })
      contentParams = JSON.stringify(contentParams)

      add({
        batchTitle: this.name,
        employeeIds,
        content: contentParams,
      }).then(_ => {
        this.$message.success('添加成功')
        this.$router.back();
      })
    },
    userGet() {
      this.$refs.groups.show()
    },
    getCount(){
      this.userName = String(this.$refs.groups.get().map(v => {
        return v.name;
      }))
    }
  },
  components: {selectEmployee, annexPopup}
}
</script>

<style lang="less" scoped>
.block {
  margin-bottom: 16px;
  background: #fff;
  background: #fff;
  padding: 3px 5px;
  border-radius: 7px;

  .title {
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 13px;
      background: #1890ff;
      margin-right: 6px;
    }
  }

  .tips {
    width: 96%;
    margin: 0 auto;
    font-size: 12px;
    line-height: 24px;
    background: #f7fbff;
    border: 1px solid #cce9ff;
    border-radius: 3px;
    color: #000000a6;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 8px;

    p {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;

      &:before {
        width: 3px;
        height: 3px;
        background: #95cbf3;
        content: "";
        display: inline-block;
        margin-left: 9px;
        margin-right: 4px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.form {

  .label {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .text {
    font-size: 12px;
    color: #00000073;
    margin-left: 6px;
  }
}

.input-block-box {
  max-width: 370px;

  .textarea {
    width: 100%;
    height: 178px;
    overflow: auto;
    outline: none;
    padding: 8px 16px;
    font-size: 13px;
    background: none;
    border: none;
  }

  .add {
    padding: 8px 16px;
    border-top: 1px solid #eee;
    font-size: 13px;
  }
}


.msg-list {
  border-top: 1px solid #eee;
  .item {
    font-size: 14px;
    padding: 8px 16px;
  }
  i {
    cursor: pointer;
  }
  img {
    width: 25px;
    height: 25px;
  }
}
</style>
